export const styles = {
    segmentStyle: { backgroundColor: "white", padding: "12px", border: "1px solid #F0F0F0", borderRadius: "4px", boxShadow: "0px 5px 6px -4px rgba(2, 34, 19, 0.02)"},
    mainGreen: "#6FBB98",
    subGreen: "#D1E4CE",
    bgGreen: "#F4FBF3",
    blue: "#5499F8",
    orange: "#E27837",
    subOrange: "#FFF7F3",
    red: "#EE685F",
    midRed: "#f4a49f",
    subRed: "#FDEFEF",
    mainText: "#161E16",
    subText: "#5E645E",
    secondaryText: "#9696A0"
}