import React from 'react';
import { Route, Switch } from "react-router-dom";
import NotFound from "./SharedComponents/NotFound";
import AppIndex from "./App/AppIndex";
import Login from "./App/Login";
import Signup from "./App/Signup";
import ResetPassword from "./App/ResetPassword";
import NewPassword from "./App/NewPassword";
import SignupVerification from "./App/SignUpVerification";
import AuthPortal from "./App/AuthPortal";
import UpdateProfile from "./App/UpdateProfile";
import Faqs from "./App/Faqs";
import Pricing from "./App/Pricing";
import Landing from "./App/Landing";
import CheckoutSuccess from "./App/CheckoutSuccess";
import AboutUs from "./App/AboutUs";
import Resources from "./App/Resources";
import TermsConditions from "./App/TermsConditions";
import PrivacyPolicy from "./App/PrivacyPolicy";
import Newsletter from "./App/Newsletter";
import ContactUs from "./App/ContactUs";
import ScrapePortal from "./App/ScrapePortal";
import DataProcessing from "./App/DataProcessing";
import SubProcessorList from "./App/SubProcessorList";
import PrivateChatbot from "./App/PrivateChatbot";
import SearchFunction from "./App/SearchFunction";
import TestDesign from "./App/AppIndexUpdate";
import PrivateDemo from "./App/PrivateDemo";

export default ({ childProps }) => {
    return (
        
        <Switch>
            <Route exact path="/chat" component={AppIndex} />
            <Route exact path="/testing" render={(props) => <AppIndex {...props} showSidebarOnLoad={true} />} />
            <Route exact path="/chatgpt-prompt" render={(props) => <AppIndex {...props} chatgptPromptType={true} />} />
            <Route exact path="/category-prompt" render={(props) => <AppIndex {...props} categoryPromptType={true} />} />
            <Route exact path="/individual-prompt" render={(props) => <AppIndex {...props} individualPromptType={true} />} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/auth" component={AuthPortal} />
            <Route path="/signup-verification" component={SignupVerification} />
            <Route path="/profile" component={UpdateProfile} />
            <Route path="/faqs" component={Faqs} />
            <Route path="/admin" component={SearchFunction} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/resources" component={Resources} />
            <Route path="/terms-policies" component={TermsConditions} />
            <Route path="/data-processing" component={DataProcessing} />
            <Route path="/sub-processor-list" component={SubProcessorList} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/private" component={PrivateChatbot} />
            <Route path="/privatesra" component={PrivateDemo} />
            <Route path="/newsletter" component={Newsletter} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/design-update" component={TestDesign} />
            <Route path="/checkout-success" component={CheckoutSuccess} />
            <Route path="/" component={Landing} />
            
            <Route component={NotFound}/>
        </Switch>
        
    );
};
    


