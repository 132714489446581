// React Component: FileUploadComponent
import React, { Component } from 'react';
import API_Root from "../../Constants/API_Root";
import {styles} from "../../Constants/styles";

class FileUploadComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            uploadStatus: [],
            estimatedTime: 0,
        };
    }

    handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        this.setState({ files: newFiles }, this.calculateEstimatedTime);
    };

    calculateEstimatedTime = () => {
        // job runs every 60 minutes
        let totalTime = 60;
        this.state.files.forEach((file) => {
            // 5 minutes per file for default
            totalTime += 5
            const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to MB
            if (fileSizeMB > 2) {
                totalTime += Math.ceil(fileSizeMB / 2); // 1 minute per 2MB
            }
        });
        this.setState({ estimatedTime: totalTime });
    };

    handleUpload = async () => {
        const formData = new FormData();
        this.state.files.forEach((file) => formData.append('files', file));

        try {
            const response = await fetch(API_Root + '/save-manual-upload-files', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            this.setState({ uploadStatus: result });
        } catch (error) {
            console.error('Upload failed:', error);
            alert('Some files failed to upload.');
        }
    };

    downloadProgressReport = () => {
        window.location.href = API_Root + 'download-upload-progress-report'; // Replace with your Flask server URL
    }

    render() {
        const { files, uploadStatus, estimatedTime } = this.state;
        return (
            <div>
                <p>Upload files below</p>
                <input type="file" multiple onChange={this.handleFileChange} />
                <p>Estimated upload time: {estimatedTime} minutes</p>
                <button onClick={this.handleUpload}>Upload Files</button>

                {uploadStatus.length > 0 && (
                    <div>
                        <p>Upload Results</p>
                        <ul>
                            {uploadStatus.map((status, index) => (
                                <li key={index}>
                                    {status.fileName} - {status.success ? 'Uploaded' : 'Failed'}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div style={{ marginTop: "16px"}}>
                    <p style={{ color: styles.blue, cursor: "pointer"}} onClick={this.downloadProgressReport}>Download progress report</p>
                </div>
            </div>
        );
    }
}

export default FileUploadComponent;
